//Link Line Drawing Script

jQuery( function($){


    //Check if current page has Link Line
    if( $('.link-line-start').length && $('.link-line-end').length ){
        
        setTimeout( function(){ 

            //Line options
            var LeaderLineOptions = {
                dash: true,
                color: '#333333',
                gradient: {
                    startColor: 'rgba(30, 30, 30, 1)',
                    endColor: 'rgba(223, 40, 23, 0.33)'
                },
                size: 2,
                startPlug: 'behind',
                endPlug: 'behind',
                path: 'straight',//'straight',
                animation: true,
                animOptions: {
                    duration: 500,
                    timing: 'ease-out'
                },
                startSocket: 'auto',
                endSocket: 'auto',

                hide: true
 
            };

          
            //For each line
            var nbLinesL = $('.link-line-left').length;
            var nbLinesR = $('.link-line-right').length;

            var indexLineL = 0;
            var indexLineR = 0;

            $('.link-line-start').each( function( indexLine ){
            
                var LinkLineEND = $(this);
                var LinkLineSTART = $('.link-line-end');

                var LinkLineDIR = $(this).hasClass('link-line-right') ? 'left' : $(this).hasClass('link-line-left') ? 'right' : 'auto';
                LeaderLineOptions.endSocket = LinkLineDIR;

                var LinkLineAncX = $(this).hasClass('link-line-right') ? '0%' : '100%';
                
                if( $(this).hasClass('link-line-right')  ){
                    var nbLinesCurrent =  nbLinesR;
                    indexLineR = indexLineR + 1;
                    var indexLineCurrent =  indexLineR;
                }else{
                    var nbLinesCurrent =  nbLinesL;
                    indexLineL = indexLineL + 1;
                    var indexLineCurrent =  indexLineL;
                }
                
                var LinkLineAncY = ( 100 / (nbLinesCurrent + 1) ) * indexLineCurrent + '%';
                

                //Create Line
                var newLine = new LeaderLine(
                    LinkLineEND[0],
                    LeaderLine.pointAnchor(LinkLineSTART[0], 
                        {
                            element: LinkLineSTART,
                            x: LinkLineAncX,
                            y: LinkLineAncY
                        }
                    ),
                    LeaderLineOptions
                );


                //Show Line
                newLine.show(
                    {
                        showEffectName: 'draw'
                    }
                );
            
            });

            
        }, 250);
    }

});

