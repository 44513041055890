//Plugin FacetWP custom scripts


/**
 * Scroll to the top after ajax refresh
 */
(function($) {
    document.addEventListener('facetwp-loaded', function() {
      
      if( $(".facetwp-autoscroll").length ){
        $('html, body').animate({ scrollTop: $(".facetwp-autoscroll").offset().top }, 500);
      }
        
     });
})(jQuery);


/**
 * Show Reset Button on after Facet Loading
 */
 (function($) {
    document.addEventListener('facetwp-refresh', function() {
        
        var reset = false;

        for (const [key, facet] of Object.entries(FWP.facets)) {
            
            if( facet.length > 0 ){
                
                reset = true;
                $('.archive-filters-reset').removeClass('d-none');
            }
        }

        if(!reset){
            $('.archive-filters-reset').addClass('d-none');
        }
     });
})(jQuery);

/**
   * Show Load more Button if more items only
   */
 (function ($) {
    document.addEventListener('facetwp-loaded', function () {

      if( FWP ) {
        if(FWP.settings.pager.total_rows > ( FWP.settings.pager.page * FWP.settings.pager.per_page ) ) {
          $('.archive-loadmore').removeClass('d-none');
        }else{
          $('.archive-loadmore').addClass('d-none');
        }
      }else{
        $('.archive-loadmore').addClass('d-none');
      }

    });
  })(jQuery);


