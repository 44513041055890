//Diagram Content Script
console.log('test')
jQuery(function($){
    
    if( typeof(DiagramContentItems) !== 'undefined' ){
        initDiagramContent(DiagramContentItems);
    }

    var prevPelletID = '';
    var nextPelletID = '';

    function initDiagramContent(ContentItems){
        
        var ids = Object.keys(ContentItems);

        $('svg #links > g').on('click', function(event){

            var pellet = event.currentTarget;
            var pelletID = $(pellet).attr('id');
            var curr = ids.indexOf(pelletID);
            
            prevPelletID = curr > 0 ? ids[curr - 1] : undefined;
            nextPelletID = curr < 18 ? ids[curr + 1] : undefined;

            if( typeof(ContentItems[pelletID]) !== 'undefined' ){

                console.log("ContentItems[pelletID]", ContentItems[pelletID]);

                //Update Modal content
                $('#DiagramContentModal .modalitem-title').html( ContentItems[pelletID]['title'] );
                $('#DiagramContentModal .modalitem-content').html( ContentItems[pelletID]['content'] );

                if( ContentItems[pelletID]['category_name'] ){
                    $('#DiagramContentModal .modalitem-category').text( ContentItems[pelletID]['category_name'] );
                    $('#DiagramContentModal .modalitem-icon').html( '<img src="'+ ContentItems[pelletID]['category_icon'] + '" />' );
                    $('#DiagramContentModal .diagram-modalitem-header')[0].className = $('#DiagramContentModal .diagram-modalitem-header')[0].className.replace(/\bbg-.*?\b/g, '');
                    $('#DiagramContentModal .diagram-modalitem-header').addClass( 'bg-' + ContentItems[pelletID]['category_color'] );
                }else{
                    $('#DiagramContentModal .modalitem-category').text( '' );
                    $('#DiagramContentModal .modalitem-icon').html( '' );
                    $('#DiagramContentModal .diagram-modalitem-header')[0].className = $('#DiagramContentModal .diagram-modalitem-header')[0].className.replace(/\bbg-.*?\b/g, '');
                }


                //Open Modal
                $('#DiagramContentModal').modal('show');
                
                // Adds and manages nav buttons
                if(prevPelletID){
                    $('.prev-modal-item').css('display', 'inline-block');
                    $('.prev-modal-item').attr('data-prev-pellet', prevPelletID)
                } else {
                    $('.prev-modal-item').css('display', 'none');
                }
                
                if(nextPelletID){
                    $('.next-modal-item').css('display', 'inline-block');
                    $('.next-modal-item').attr('data-next-pellet', nextPelletID)
                } else {
                    $('.next-modal-item').css('display', 'none')
                }

            }

        });

    }

    $('.diagram-nav-btn.prev-modal-item').on('click', function(){
        $('svg #links > g#' + prevPelletID ).click();

        $('#DiagramContentModal .diagram-modalitem-header').removeClass( 'blue' );
        $('#DiagramContentModal .diagram-modalitem-header').removeClass( 'red' );
        $('#DiagramContentModal .diagram-modalitem-header').removeClass( 'green' );
    });
    $('.diagram-nav-btn.next-modal-item').on('click', function(){
        $('svg #links > g#' + nextPelletID ).click();

        $('#DiagramContentModal .diagram-modalitem-header').removeClass( 'blue' );
        $('#DiagramContentModal .diagram-modalitem-header').removeClass( 'red' );
        $('#DiagramContentModal .diagram-modalitem-header').removeClass( 'green' );
    });

    //Open Diagram Info in Popover
    function triggerDiagramPopover(elem, action){
        console.log('in popover');
        var popoverID = $(elem).attr('id');
        popoverID = popoverID.replace('i', '');
        popoverID = popoverID.replace('l', '');
        var popoverContent = $(elem).closest('svg').find('#popover #p'+popoverID);
        
        if( !popoverContent ){
            return;
        }
        
        if( action == 'show' ){
            $(popoverContent).show();
        }else{
            setTimeout(function(){
                $(popoverContent).hide();
            }, 500);
        }
    }

    //Listner :
    $('body').on('mouseenter', '.diagram-container svg #infos g[id^=i]', function(){
        triggerDiagramPopover(this, 'show');
    }).on('mouseleave', '.diagram-container svg #infos g[id^=i]', function(){
        triggerDiagramPopover(this, 'hide');
    });

    $('body').on('mouseenter', '.diagram-container svg #links g[id^=l]', function(){
        triggerDiagramPopover(this, 'show');
    }).on('mouseleave', '.diagram-container svg #links g[id^=l]', function(){
        triggerDiagramPopover(this, 'hide');
    });
        

    //Show diagram in full size
    $('body').on('click', '.diagram-fullsize', function(elem){

        $('button.diagram-fullsize').toggleClass('btn-dark');
        $('button.diagram-fullsize').toggleClass('btn-outline-primary');
        $('.diagram-container').toggleClass('full-size');

        if( $('button.diagram-fullsize').hasClass('btn-dark') ){
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".diagram-container").offset().top
            }, 200);
        }

    });
});

