// Add your custom JS here.

/////////////// Select2 Script ////////////////////
jQuery(document).ready(function($) {
    var userLang = navigator.language || navigator.userLanguage;
    userLang = userLang ? userLang : 'fr';

    $('select.select2').select2({
        language: userLang,
        theme: "default",//"bootstrap4",
    });
});


