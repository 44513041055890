//Plugin BuddyForms custom scripts


/**
 * Auto hide/show field : Lorier "document_type"
 */
 (function($) {
    
    if( $('.the_buddyforms_form .input-conditional').length ){

        buddyforms_condInputs_DocumentType();
    }

    function buddyforms_condInputs_DocumentType(){
        var documentType = $('input[name=document_type]:checked').val();

        if( documentType == 'file' ){
            $('.input-conditional .elem-document_file').show();
            $('.input-conditional .elem-document_url').hide();
        }else{
            $('.input-conditional .elem-document_file').hide();
            $('.input-conditional .elem-document_url').show();
        }
    }

    $('input[name=document_type]').change(function(){
        buddyforms_condInputs_DocumentType();
        console.log('Document Type changed');
    });

})(jQuery);
