////////////// Bootstrap Extend Navigations Script /////////////////

jQuery(document).ready(function($){

    //Navigation Add class menu to grandparentCurrent item
    $('nav li li.current-menu-parent').each(function(index, li){
        //Grandparent level 2
        $(li).closest('ul').parent().addClass('current-menu-parent active');
        
        //Grand-grandparent level 3
        $(li).closest('ul').parent().closest('ul').parent().addClass('current-menu-parent active');
    });

    //Navigation : Add parent dropdown link clickable
    $('li.dropdown a').on('click', function() {
        
        var $a = $(this);

        if ( $a.hasClass('clickable') ) {
            
            if ( $a.attr('href') ) {
                location.href = $a.attr('href');
            }
        }
    });

    //Navigation for 3nd level : Show / Hide dropdown
    /*
    $('li.dropdown li.dropdown').on('mouseenter', function() {
        var $a = $(this).children('a');
        var $ul = $(this).children('ul');
        $($a).addClass('show');
        $($ul).addClass('show');

    });

    $('li.dropdown li.dropdown').on('mouseleave', function() {
        var $a = $(this).children('a');
        var $ul = $(this).children('ul');
        $($a).removeClass('show');
        $($ul).removeClass('show');

    });
    */


    //Navigation : Show dropdown on hover
    $('li.dropdown').on('mouseenter', function() {
        
        var $a = $(this).children('a.dropdown-toggle');
        if (!$a.hasClass('show')) {
            
            if ($a.length) {
                
                if(!!('ontouchstart' in window)){//check for touch device
                    $a.addClass('clickable-mobile');
                }else{
                    //Hide all other
                    $('li.dropdown.show a.dropdown-toggle').dropdown('toggle');
                    //Show this one
                    $a.dropdown('update');
                    $a.dropdown('toggle');
                    $a.addClass('clickable');
                }
            }
        }
    });

    //Navigation : Hide dropdown on mouseleave
    $('li.dropdown').on('mouseleave', function() {
        
        var $a = $(this).children('a.dropdown-toggle');
        if ($a.length) {

            if(!!('ontouchstart' in window)){//check for touch device
                $a.removeClass('clickable-mobile');
            }else{
                //Show this one
                $a.dropdown('toggle');
                $a.removeClass('clickable');
            }
        }
    });
});