////////////// ClipBoard Copy //////////////////////
jQuery(function($){

    function CopyToClipBoard (text, button){

        if(!text){ return null; }

        navigator.clipboard.writeText(text).then(function() {
            console.log('Async: Copying to clipboard was successful!', text);
            
            //Alert
            var AlertHtml = '<div id="AlertCopyToClipBoard" class="alert alert-success fixed-bottom mx-5 my-4 w-80" role="alert">Lien copié dans le presse-papiers !</div>';
            $('body').append(AlertHtml);

            var AlertBs = understrap.Alert.getOrCreateInstance('#AlertCopyToClipBoard');
            setTimeout(function(){
                AlertBs.close();
                AlertBs.dispose();
            }, 3500);


        }, function(err) {
            console.error('Async: Could not copy text: ', err);
            window.prompt('Contenu à copier', text);
        });

        return text;

    }

    //Trigger
    $('.copy-to-clipboard').on('click', function(){

        var copyText = $(this).data('copy');
        return CopyToClipBoard(copyText, this);

    });
    
});


