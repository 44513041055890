//Show post in Bootstrap Modal

jQuery(document).ready(function($){

    //Create modal
    if( $("a[target='#post-in-modal']").length ){

        
        let ModalHtml = '\
        <div id="post-modal" class="modal fade modal-post" style="" tabindex="-1" aria-labelledby="post-in-modal" aria-hidden="true" role="dialog">\
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document" >\
                <div class="modal-content">\
                    <div class="modal-header">\
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">\
                        </button>\
                    </div>\
                    <div class="modal-body">\
                    <iframe id="post-in-modal" style="display:none;">...</iframe>\
                    </div>\
                </div>\
            </div>\
        </div>\
        ';

        $("#page").append(ModalHtml);

    }


    //Trigger modal
    $("#page").on('click', "a[target='#post-in-modal']", function(e){
        
        e.preventDefault();

        //Clean 
        $('#post-modal .modal-body').html('Chargement...');



        //Load
        var postUrl = $(this).attr('href');
        var postId = $(this).data('post-id') ? $(this).data('post-id') : '';
        var postSlug = $(this).data('post-slug') ? $(this).data('post-slug') : '';
        var postType = $(this).data('post-type') ? $(this).data('post-type') : '';

        var modalTitle = $(this).data('modal-title') ? $(this).data('modal-title') : '';
        var loadMode = $(this).data('modal-mode') ? $(this).data('modal-mode') : '';
        var closeMode = $(this).data('modal-close') ? $(this).data('modal-close') : '';

        console.log('loadMode', loadMode);

        if(closeMode == 'reload'){
            $('#post-modal').attr('data-onclose', 'reload');
        }else{
            $('#post-modal').removeAttr('data-onclose');
        }


        loadPostInModal(postUrl, postId, postSlug, postType, loadMode, function(postData){

            $('#post-modal .modal-body').html(postData);

            if(modalTitle){
                $('#post-modal .modal-header .modal-title').remove();
                $('#post-modal .modal-header').prepend('<h5 class="modal-title">'+modalTitle+'</h5>');
            }else{
                $('#post-modal .modal-header .modal-title').remove();
            }

            //$('#post-modal').attr('style', '');
        });
        

        //Open
        $('#post-modal').modal('toggle');


    });

    //Load post
    function loadPostInModal(postUrl, postId, postSlug, postType, loadMode, callback){

            var postData = "...";

            var postUrl = postUrl ? postUrl : '';
            var postId = postId ? postId : '';
            var postSlug = postSlug ? postSlug : '';
            var postType = postType ? postType : '';
            var loadMode = loadMode ? loadMode : '';
    
            var params = { 
                'action': 'understrap_ajax_get_post',
                'post_id': postId,
                'post_slug': postSlug,
                'post_type': postType,
                'post_url': postUrl,
                'mode': loadMode,
            };

            $.ajax({
                url: wp.ajax.settings.url,
                method: "GET",
                data: params,
                dataType: "html"
            })
            .done(function(data) {
                postData = data;
                return callback(postData);
            })
            .fail(function(error) {
                postData = "Error ...";
                console.log('Error load Post in modal:', error);
                return callback(postData);
            })
            .always(function() {

            });
    }


    //On close
    $('#post-modal').on('hidden.bs.modal', function (event) {
        
        
        if( $(this).attr('data-onclose') == 'reload' ){
        
            $('body').css('opacity', '0.2');
            $('body').css('cursor', 'progress');
            
            location.reload();
        }

    });

});